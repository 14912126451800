<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import Heading from '@/Components/Heading.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import FormButton from '@/Components/FormButton.vue';

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <GuestLayout>
        <Head :title="$t('auth.log_in.title')" />

        <Heading class="mb-6">
            {{ $t('auth.log_in.heading') }}
        </Heading>



        <form @submit.prevent="submit">
            <div class="my-4">
                <InputLabel for="email" :value="$t('labels.email')" />

                <TextInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    :placeholder="$t('placeholders.email')"
                    required
                    autofocus
                    autocomplete="username"
                />

                <InputError :message="form.errors.email" />
            </div>

            <div class="mb-8">
                <InputLabel for="password" :value="$t('labels.password')" />

                <TextInput
                    id="password"
                    v-model="form.password"
                    type="password"
                    :placeholder="$t('placeholders.password')"
                    required
                    autocomplete="current-password"
                />

                <InputError :message="form.errors.password" />
            </div>

            <div class="mb-8">
                <FormButton
                    class="btn btn-primary btn-block"
                    :processing="form.processing">
                    {{ $t('auth.log_in.action') }}
                </FormButton>
            </div>

            <div class="mb-16">
                <Link
                    :href="route('password.request')"
                    class="underline"
                >
                    {{ $t('auth.log_in.forgot_password') }}
                </Link>
            </div>

            <div class="mb-16">
                {{ $t('auth.log_in.no_account') }}
                <Link
                    :href="route('register')"
                    class="underline"
                >
                    {{ $t('auth.log_in.register') }}
                </Link>
            </div>
        </form>
    </GuestLayout>
</template>
